@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');

/* Global variables */
:root {
  --black: #000000;
  --pink: #f73ea4;
  --blue: #445dfc;
  --purple: #8e38ff;
  --translucent: rgba(255,255,255,0.5);
  --translucent-dark: rgba(0,0,0,0.5);
  --white: #ffffff;
}

* {
  box-sizing: border-box;
  margin: 0;
}

html, body {
  width: 100%;
  height: 100%;
  background: var(--black);
}

.page {
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.container {
	width: 100%;
	height: auto;
	min-height: 100%;
	position: relative;
	background: linear-gradient(0deg, var(--blueFour) 0%, var(--blueThree) 100%);
	padding: 80px 40px;
	overflow: hidden;
}

.content {
	width: 100%;
	max-width: 1000px;
	height: 100%;
	margin: 0 auto;
}

#canvas {
	position: absolute;
	top: 0;
  left: 0;
  z-index: 0;
}

.content_sizer {
	width: 100%;
	max-width: 420px;
	height: 100%;
}

h1 {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 80px;
	background: -webkit-linear-gradient(var(--pink), var(--purple), var(--blue));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 20px;
	color: var(--translucent);
	line-height: 1.5;
}

a {
	text-decoration: none;
}

.white {
	color: var(--white);
}

img.link_icon {
	width: 20px;
	height: 20px;
}

.spacer {
	width: 10px;
	height: 30px;
}

.horizontal_spacer {
	width: 10px;
	height: 10px;
}

.grid {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, auto);
	grid-template-rows: repeat(3, auto);
	grid-gap: 40px;
}

.grid_item {
	min-width: 100px;
}

.grid_item img {
	width: auto;
	height: auto;
	max-width: 100px;
	max-height: 35px;
	opacity: 0.5;
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.vertical_flex {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.flex_item {
	flex: 0 0 content;
}

.flex_equal {
	flex: 1 0 0;
}

.flex_space {
	flex: 1 1 auto;
}

.justify_start {
	justify-content: flex-start;
}

.justify_end {
	justify-content: flex-end;
}

@media only screen and (max-width: 800px) {
  .page {
    background-color: var(--translucent-dark);
  }

	#canvas {
		filter: blur(20px);
	}

	.content_sizer {
		max-width: 100%;
	}

	.grid {
		grid-gap: 40px 10px;
	}
}